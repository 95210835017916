import * as React from "react"
import { Link } from "gatsby"
import Layout from '/src/components/layout'

const NotFoundPage = () => {
  
	return (
        <Layout pageTitle="404 Not found">

			<p className="intro">Sidan du försöker nå finns inte.</p>
        	<p style={{textAlign: 'center'}}><Link to="/">Gå till startsidan</Link></p>

		</Layout>
	)
}

export default NotFoundPage